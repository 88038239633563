import React from "react"
import { inject, observer } from "mobx-react"
import { Link } from "gatsby"
import ReactMarkdown from "react-markdown/with-html"
import Layout from "../components/layout"
import colors from "../components/colors"
import ContactBanner from "../components/ContactBanner"
import Newsletter from "../components/Newsletter"

import de_news from "../../content/de_news.json"
import en_news from "../../content/en_news.json"



const News = props => {
  
  const { language, device } = props.store
  const styles = device === 'desktop'? desktopStyles : mobileStyles
  
  const news = 
    language === "en" ? en_news.news[0] : de_news.news[0]

  return (
    <Layout>
    <div >
      <div style={styles.container}>
        <img
        style={{width:'100%', maxHeight:'150px', objectFit:'cover'}}
        src={device==='mobile'?news.upperVisualMobile:news.upperVisual}
        alt="Fans cheering"
      />
        <div style={styles.contentContainer}>
          <div style={styles.upperSection}>
            <div style={styles.breadCrumbContainer}>
              <Link to={"/"} style={styles.link}>
                Home
              </Link>
              {" / "}
              BVB
            </div>
            <div style={{paddingBottom:'0px', marginBottom: '1.45rem'}}>
              <h1 style={{color:colors.black, fontSize:'3.1em'}}>
                {news.title}
              </h1>
              <ReactMarkdown
                  source={news.description}
                  escapeHtml={false}
                />
            </div>
          </div>
          
          
        </div>

        
      </div>
      <Newsletter background={colors.white} news={true} audience={'bvb'}/>
      
        <div style={styles.container}>
        <img
        style={{width:'100%', objectFit:'cover', display: 'block', paddingTop:'100px'}}
        src={device=== 'mobile' ?news.lowerVisualMobile:news.lowerVisual}
        alt="BVB Players"
      />
        <div/>
      </div>

      <ContactBanner backgroundColor={colors.greyBackground} news={true}/>
      
      </div>
    </Layout>
  )
}

export default inject("store")(observer(News))

const desktopStyles = {
  container: { backgroundColor: colors.greyBackground, position:'relative' },
  contentContainer: {
    maxWidth: "80%",
    margin: "auto",
    color: colors.textDarkgrey,
    padding: "50px 0px",
  },
  breadCrumbContainer: { paddingBottom: "50px" },
  upperSection: { padding: "50px 0px" },
  link: { color: colors.textDarkgrey, textDecoration: "none" },
  headerSection: { display: "flex",marginBottom: '1.45rem', justifyContent: "space-between" },
  header: {
    fontSize: "1.5625rem",
    color: colors.black,
  },
  description: { width: "70%" },
  workshopSection: {
    width: "105%",
    display: "flex",
    flexWrap: "wrap",
    //justifyContent: "flex-start",
    //alignItems: "flex-start",
  },
  lowerSection: { padding: "50px 0px" },
  mediumSection: { backgroundColor: "white" },
  mediumContainer: {
    maxWidth: "80%",
    margin: "auto",
    padding: "50px 0px",
  },
}

const mobileStyles = {
  container: { backgroundColor: colors.greyBackground },
  contentContainer: {
    maxWidth: "80%",
    margin: "auto",
    color: colors.textDarkgrey,
    //padding: "50px 0px",
  },
  breadCrumbContainer: { paddingBottom: "50px" },
  upperSection: { padding: "50px 0px" },
  link: { color: colors.textDarkgrey, textDecoration: "none" },
  headerSection: { display: "flex",flexDirection:'column', marginBottom: '1.45rem', justifyContent: "space-between" },
  header: {
    fontSize: "1.5625rem",
    color: colors.black,
  },
  description: { width: "100%" },
  workshopSection: {
    width: "100%",
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  lowerSection: { padding: "50px 0px" },
  mediumSection: { backgroundColor: "white" },
  mediumContainer: {
    maxWidth: "80%",
    margin: "auto",
    padding: "50px 0px",
  },
}
/*
<div style={styles.lowerSection}>
            <div style={styles.headerSection}>
              <h2 style={styles.header}>{'text auch hier'} </h2>
              <p style={styles.description}>
                {}
              </p>
            </div>
            <div style={styles.workshopSection}>
              
            </div>
          </div>
          */ 